import React from 'react';

const componentName = () => {
    return (
        <div>
            Page not found. Go to <a href="/">HOME</a>.
        </div>
    );
};

export default componentName;