import React from 'react';
import Card from '../components/Card';
import Navigation from '../components/Navigation';
    
    
    const Portfolio = () => {
        return (
            <div className="main">
                <Navigation/>
                <div className="contentWrapper">
                    <div className="title">
                        MES PROJETS
                        <hr/>
                    </div>
                    <div className="portfolioContent">
                        <Card title="TICTACTOE" description="Petit projet fait à la maison." technos={["html", "css", "js"]} image="tictactoe.jpg" />
                        <Card title="PORTFOLIO" description="Le site que vous êtes actuellement en train de consulter." image="user.svg" technos={["reactjs"]}/>
                    </div>
                </div>
            </div>
        );
    };
    
    export default Portfolio;