import React from 'react';
import { NavLink } from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap'

const Navigation = () => {
    return (
        <div className="header">
            <Navbar expand="lg" className="header-strip">
                <Navbar.Brand className="header-name">TONY BASSO</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end header-links">
                    <Nav.Link><NavLink exact to="/#me" activeClassName="navActive">A PROPOS DE MOI</NavLink></Nav.Link>
                    <Nav.Link><NavLink exact to="/creations" activeClassName="navActive">CREATIONS</NavLink></Nav.Link>
                    <Nav.Link><NavLink exact to="/contact" activeClassName="navActive">CONTACT</NavLink></Nav.Link>
                </Navbar.Collapse>
            </Navbar>
            
        </div>
        
    );
};

export default Navigation;