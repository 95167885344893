import { Button, ButtonGroup, Col, Container, Row, ToggleButton } from 'react-bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter, faGitlab } from '@fortawesome/free-brands-svg-icons'
import Card from '../components/Card';
import { Link } from "react-scroll";

class AboutMe extends React.Component {

   

    
render() {

    let desc = {
        "portfolio" : "Ce portolio.",
        "tyllt" : "Thème wordpress",
        "tictactoe" : "Jeu de TicTacToe, aussi appelé Morpions",
        "adopteuncoupain" : "Faux site de rencontres, où le but est de liker ou disliker une personne. Je réccupère des fausses personne avec l'API randomuser.me et j'affiche leur localisation grâce à l'API mapbox.com",
    }

    let technos = {
        "portfolio" : "ReactJS",
        "tyllt" : "Wordpress, PHP, JS",
        "tictactoe" : "JS",
        "adopteuncoupain" : "AngularJS",
    }

    return (
            <div className="main">
                <div style={{ backgroundImage: "url(/media/am-bg.png)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <Container className="d-flex vh-100 flex-column am-home">
                        <Row className="">
                            <Col>
                                <Row className="">
                                    <Col className="text-center">
                                        <h1>TONY BASSO</h1>
                                        <hr></hr>
                                        <h2>ETUDIANT WEB DEVELOPPEUR</h2>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col className="am-home-col text-center">
                                    <ButtonGroup aria-label="Basic example">
                                        <a  href="mailto:tony.basso33@gmail.com"><Button className="btn-social"><FontAwesomeIcon icon={faEnvelope}/></Button></a>
                                        <a  href="https://fr.linkedin.com/in/tony-basso"><Button className="btn-social"><FontAwesomeIcon icon={faLinkedin}/></Button></a>
                                        <a  href="https://gitlab.com/tony.basso33"><Button className="btn-social"><FontAwesomeIcon icon={faGitlab}/></Button></a>
                                        <a  href="https://twitter.com/tonybasso33"><Button className="btn-social"><FontAwesomeIcon icon={faTwitter}/></Button></a>
                                    </ButtonGroup>
                                    </Col>
                                </Row>  

                              
                                </Col>
                                </Row>
                                <Row className="d-flex flex-fill fill">
                                    <Col className="h-100  d-flex align-items-end justify-content-center ">
                                        <Button className="btn-creations">
                                            <Col>
                                            <Row className="justify-content-center"><Link activeClass="active" to="creations" spy={true} smooth={true} duration={500}>
                                                            CREATIONS
                                                        </Link></Row>
                                            <Row className="justify-content-center"><img className="arrow" src="/media/chevron-down.svg" width="50" alt=""/></Row>
                                        
                                            </Col>
                                                        

                                        </Button>

                                    </Col>
                                </Row>
                    </Container>
                </div>
                <div className="creations" style={{ backgroundImage: "url(/media/am-bg.png)", backgroundRepeat: 'repeat', backgroundSize: 'contain'}}>
                    <Container>
                        <Card img="am-bg.png" title="PORTFOLIO" desc={desc["portfolio"]} technos={technos["portfolio"]}></Card>                                
                        <Card img="tyllt-logo.svg" title="Tyllt" link="https://tyllt.com" desc={desc["tyllt"]} technos={technos["tyllt"]}></Card>                                
                        <Card img="tictactoe.jpg" title="TicTacToe" link="https://tictactoe.tony-basso.com" desc={desc["tictactoe"]} technos={technos["tictactoe"]}></Card>                                
                        <Card img="auc.png" title="Adopte Un Coupain" link="https://adopte-un-coupain.tony-basso.com" desc={desc["adopteuncoupain"]} technos={technos["adopteuncoupain"]}></Card>                                
                    </Container>
                </div>
            </div>

        );
    }
}

export default AboutMe;