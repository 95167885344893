import React from 'react';
import Navigation from '../components/Navigation';

const Contact = () => {
    return (
        <div className="main">
            <Navigation/>
            <div className="am-home">
                <h1>CONTACT</h1>

            </div>
        </div>
    );
};

export default Contact;