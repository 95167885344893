import React from 'react';
import { Col, Row } from 'react-bootstrap';

class Card extends React.Component {

    render() { 
        let img = "";

        let title = this.props.title ?? "";
        let desc = this.props.desc ?? "";
        let technos = this.props.technos ?? "";
        
        //make url of background
        if(this.props.img !== "")
            img = "/media/"+this.props.img;



        return (
            <Row className="rowWrapper justify-content-center" onClick={(e) => {
                e.preventDefault();
                console.log(window.location);

                //if link is filled, add click redirect
                if(this.props.link !== undefined)
                    window.location.href=this.props.link;

                }}>
                <div className="cardWrapper">
                    <Row noGutters>
                        <Col sm="auto" className="cardImg">
                            <img src={img} alt="Logo"/>
                        </Col>
                        <Col sm="7">
                            <Row>
                                <Col><h2 className="title">{title}</h2></Col>
                            </Row>

                            <Row>
                                <Col><p className="desc">{desc}</p></Col>
                            </Row>

                            <Row>
                                <Col><p className="desc"><b>Technos: </b>{technos}</p></Col>
                            </Row>
                        </Col>  
                    </Row> 
                </div>   
                
                <div className="cardWrapperMobile">
                    <Row noGutters>
                        <Col>
                            <Row noGutters className="topRow">
                                <Col>
                                    <img src={img} alt="Logo"/>
                                    <h2 className="title h-100 my-3">{title}</h2>
                                </Col>
                            </Row>

                            <Row className="bottomRow">
                                <Col>
                                    <Row>
                                    <Col><p className="desc">{desc}</p></Col>
                                    </Row>
                                    <Row>
                                    <Col><p className="desc"><b>Technos: </b>{technos}</p></Col>
                                    </Row>
                                </Col>  
                            </Row>
                        </Col>
                        
                        
                    </Row> 
                </div>   
            
            </Row>
        );
    }
}

export default Card;