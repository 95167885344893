import React from 'react';

import {BrowserRouter, Route, Switch} from 'react-router-dom';

import AboutMe from './pages/AboutMe';
import Contact from './pages/Contact';
import Portfolio from './pages/Creations';
import NotFound from './pages/NotFound';

const App = () => {
  return (
    
    <BrowserRouter>
      <Switch>
          <Route path="/" exact component={AboutMe} />
          <Route path="/contact" component={Contact} />
          <Route path="/creations" component={Portfolio} />
          <Route component={NotFound} />
       </Switch>
    </BrowserRouter>
  )};

export default App;